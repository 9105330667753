<template>
	<div>
		<BaseEmpty>
			<ChartFilters
				@findTransactions="search"
				:showSelect="{
					grouping: false,
					category: false,
					month: true,
					year: true,
					type: true
				}"
				:defaultFilters="{
					type: 'line'
				}"
			/>
		</BaseEmpty>
		<Base addWidth="30px" style="margin-top: 20px;">
			<div>
				<ChartMedia
					:title="`Análise Comparativa`"
					:showXAxisLabel="true"
					:showZoom="true"
					:chartKey="2"
					:showLegend="true"
					:filters="filters"
				/>
			</div>
		</Base>
	</div>
</template>

<script>
	import Base from "@/templates/Base";
	import BaseEmpty from "@/templates/BaseEmpty.vue";
	import ChartMedia from "@/components/charts/ChartMedia.vue";
	import ChartFilters from "@/components/charts/ChartFilters.vue";

	export default {
		components: {
			ChartMedia,
			Base,
			ChartFilters,
			BaseEmpty
		},

		data () {
			return {
				filters: {}
			};
		},

		computed: {

		},

		watch: {
		},

		async mounted () {
		},

		methods: {
			search (filters) {
				this.filters = filters;
			}
		}
	};
</script>