var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BaseEmpty',[_c('ChartFilters',{attrs:{"showSelect":{
				grouping: false,
				category: false,
				month: true,
				year: true,
				type: true
			},"defaultFilters":{
				type: 'line'
			}},on:{"findTransactions":_vm.search}})],1),_c('Base',{staticStyle:{"margin-top":"20px"},attrs:{"addWidth":"30px"}},[_c('div',[_c('ChartMedia',{attrs:{"title":`Análise Comparativa`,"showXAxisLabel":true,"showZoom":true,"chartKey":2,"showLegend":true,"filters":_vm.filters}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }